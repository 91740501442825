<template lang="pug">
.topic-comment(:class="{'topic-comment-focus': isFocus  || commentText.length >= 1}")
  .topic-comment__wrapper(v-if="!isZend")
    .topic-comment__top
      .topic-comment__item
        img.topic-comment__img(:src="userIcon" :alt="userName")

      .topic-comment__item
        textarea.topic-comment__input(
          placeholder="Leave a comment"
          v-model.trim="commentText"
          @focus="isFocus = true"
          @blur="isFocus = false"
        )

      .topic-comment__item
        .topic-comment__button
          .button(
            :class="{'button-disable': (!fileSrc) && (v$.$invalid)}"
            @click="onCreate()"
          )
            .button__text Send

    .topic-comment__bottom
      .topic-comment__photo
        .topic-create-photo
          label.topic-create-photo__label(:class="{'topic-create-photo-corrected': fileChange}" v-if="!fileSrc")
            input.topic-create-photo__input(
              type="file"
              name="files"
              ref="files"
              id="files"
              @change="onFileChange()"
            )
            svg.topic-create-photo__icon
              use(xlink:href="#forum-form-photo")
            .topic-create-photo__title(v-html="fileText")
          .topic-create-photo__download(v-else)
            a.topic-create-photo__img(:href="fileSrc" data-fancybox="topic-create")
              img.topic-create-photo__img-wrapper(:src="fileSrc")
            svg.topic-create-photo__close(@click="onClose()")
              use(xlink:href="#faq-plus")

      .topic-comment__button-mobile
        .button(
          :class="{'button-disable': (!fileSrc) && (v$.$invalid)}"
          @click="onCreate()"
        )
          .button__text Send
  .topic-comment__success(v-else)
    .h4.center Comment created
    p.center Your comment will be added to the site soon.
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {minLength, required} from '@vuelidate/validators'

export default {
  name: 'AppTopicComment',

  props: [
      'pageId',
      'userId',
      'userIcon',
      'userName'
  ],

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      commentText: '',
      files: '',
      fileText: 'Add photo',
      fileChange: false,
      fileSrc: '',
      isFocus: false,
      isZend: false
    }
  },

  methods: {
    async onCreate () {
      if ((this.fileSrc) || (!this.v$.$invalid)) {
        let comment = {
          fields: {
            pageId: this.pageId,
            userId: this.userId,
            commentText: this.commentText
          },
          files: this.files
        }

        if ((process.env.NODE_ENV === 'development')) {
          console.log(comment)
        } else {
          const fields = {
            fields: comment.fields
          }

          const formData = new FormData()
          formData.append('fields', JSON.stringify(fields))

          if (comment.files !== '') {
            for (let i = 0; i < comment.files.length; i++) {
              const file = comment.files[i]
              formData.append('files[' + i + ']', file)
            }
          }

          const response = await fetch(`/api/v1/comment/create/`, {
            method: 'POST',
            body: formData
          })

          const result = await response.json()
          console.log(result)

          this.commentText = this.fileSrc = this.files = ''
          this.fileChange = false

          this.isZend = !this.isZend
          setTimeout(() => {
            this.isZend = !this.isZend
          }, 5000)
        }
      }
    },

    onFileChange () {
      this.files = this.$refs.files.files
      this.fileChange = true

      if (this.fileChange) {
        this.fileText = 'Photo corrected'
      }

      // img download
      let file = this.files[0]
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.fileSrc = reader.result
      }
    },

    onClose () {
      this.files = ''
      this.fileSrc = ''
      this.fileChange = false
      this.fileText = 'Add photo'
    }
  },

  validations () {
    return {
      commentText: {
        required,
        minLength: minLength(5)
      }
    }
  }
}
</script>
